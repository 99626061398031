<template>
  <div class="section-wrapper">
    <b-row>
      <div class="sec_title">
        <div class="text">
          <span>{{ currentLocale == 'en' ? 'Dhaka International Trade Fair' : 'ঢাকা আন্তর্জাতিক বাণিজ্য মেলা' }}</span>
        </div>
        <div class="line"></div>
      </div>
      <b-col sm="3">
        <b-form-group label-for="year">
          <template v-slot:label>
            {{$t('globalTrans.year')}}
          </template>
          <b-form-select plain v-model="search.year" :options="yearList" id="year">
            <template v-slot:first>
              <b-form-select-option disabled :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>
      </b-col>
      <b-col sm="3" class="mt-3">
        <b-button size="sm" variant="primary" class="mb-2" @click="searchData">
          <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
        </b-button>
      </b-col>
    </b-row>
    <!-- *********upcoming fair & calender********* -->
    <ditf-loading v-if="!dataLoad" />
    <b-row v-else>
      <b-col>
        <b-card class="upcoming-fair">
          <div class="d-flex justifiy-center align-items-center flex-column">
            <div class="icon mb-2">
              <img class="img-fluid" src="@/assets/images/local-shop.png" alt="">
            </div>
            <div class="content">
              <h5>{{ currentLocale == 'en' ? getDhakaFair()?.text_en : getDhakaFair()?.text_bn }}</h5>
              <div class="d-flex align-items-center justify-content-center">
                <h6 class="py-3 mr-2"><strong>{{ $t('externalTradeFair.city_and_country') }}: </strong> {{ currentLocale === 'en' ? 'Dhaka, Bangladesh' : 'ঢাকা, বাংলাদেশ' }}</h6>
                <p class="fair-date"><strong>{{ $t('externalTradeFair.duration') }}: </strong>{{ getDhakaFair()?.start_date ? getDuration(getDhakaFair().start_date, getDhakaFair().end_date) : '' }}</p>
              </div>
            </div>
          </div>
        </b-card>
        <div class="trade_fire_wrapper mb-4">
          <b-row>
            <b-col class="bdr" sm="4">
              <div class="info-card">
                <img src="@/assets/images/shopping-mall.png" class="img-fluid" alt="">
                <div class="content">
                  <p>{{ $t('tradeFairDashboard.total_stall') }}</p>
                  <h3>{{ $n(dashboardData.total_stall, { useGrouping: false }) }}</h3>
                </div>
              </div>
            </b-col>
            <b-col class="bdr mt-3 mt-sm-0" sm="4">
              <div class="info-card">
                <img src="@/assets/images/food-store.png" class="img-fluid" alt="">
                <div class="content">
                  <p>{{ $t('tradeFairDashboard.local_stall') }}</p>
                  <h3>{{ $n(dashboardData.local_stall, { useGrouping: false }) }}</h3>
                </div>
              </div>
            </b-col>
            <b-col class="bdr" sm="4">
              <div class="info-card">
                <img src="@/assets/images/forign-shop.png" class="img-fluid" alt="">
                <div class="content">
                  <p>{{ $t('tradeFairDashboard.foreign_stall') }}</p>
                  <h3>{{ $n(dashboardData.foreign_stall, { useGrouping: false }) }}</h3>
                </div>
              </div>
            </b-col>
          </b-row>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import RestApi, { internationalTradeFairServiceBaseUrl } from '@/config/api_config'
  import ditfLoading from '@/modules/trade-fair-service/dashboard/loading/ditf_loading'
  import { helpers } from '@/utils/helper-functions'
  export default {
    props: [],
    components: {
      ditfLoading
    },
    data () {
      return {
        dashboardData: {
          foreign_stall: 0,
          local_stall: 0,
          total_stall: 0
        },
        dhakaFairList: [],
        fairTitleList: [],
        stallCategoryList: [],
        search: {
            year: 0
        },
        dataLoad: false
      }
    },
    computed: {
      customYearList () {
          const min = new Date().getFullYear()
          const oldMax = min - 4
          const newMax = min + 0
          const newYears = []
          const oldYears = []
          for (let i = oldMax; i < min; i++) {
              oldYears.push(i)
          }
          for (let j = min; j <= newMax; j++) {
              newYears.push(j)
          }
          const yearList = [...oldYears, ...newYears]
          return yearList
      },
      currentLocale () {
          return this.$i18n.locale
      },
      yearList () {
        return helpers.getYearList({ yearSpan: 4, yearMax: 1, sorting_order: 2 })
      }
    },
    created () {
      const tmpFis = this.$store.state.CommonService.commonObj.fiscalYearList.find(item => item.value === this.$store.state.currentFiscalYearId)
      this.search.year = new Date(tmpFis.end_date).getFullYear()
      this.loadData()
    },
    watch: {
      'search.year': function (newVal) {
          this.fairTitleList = this.dhakaFair()
      },
      currentLocale: function (newVal, oldVal) {
          if (newVal !== oldVal) {
              this.fairTitleList = this.dhakaFair()
          }
      }
    },
    methods: {
      getDhakaFair () {
        return this.dhakaFairList.find(el => el.year === this.search.year)
      },
      getYearList () {
          const year = []
          this.customYearList.map(el => {
            if (this.$i18n.locale === 'bn') {
              const bn = this.$n(el, { useGrouping: false })
              year.push(bn)
            } else {
              year.push(el)
            }
          })
        return year
      },
      searchData () {
        this.loadData()
      },
      getDuration (dateFrom, dateTo) {
          const startDate = dateFrom.split('-')
          const endDate = dateTo.split('-')
          let startMonthId = ''
          let endMonthId = ''
          startMonthId = this.$store.state.commonObj.monthList.find(doc => doc.value === parseInt(startDate[1]))
          endMonthId = this.$store.state.commonObj.monthList.find(doc => doc.value === parseInt(endDate[1]))
          let customDate = ''
          if (startDate[1] === endDate[1]) {
              customDate = this.$n(startDate[2]) + ' - ' + this.$n(endDate[2]) + ' ' + (this.$i18n.locale === 'en' ? startMonthId.text_en : startMonthId.text_bn) + ', ' + this.$n(startDate[0], { useGrouping: false })
          } else {
              customDate = this.$n(startDate[2]) + ' ' + (this.$i18n.locale === 'en' ? startMonthId.text_en : startMonthId.text_bn) + ' - ' + this.$n(endDate[2]) + ' ' + (this.$i18n.locale === 'en' ? endMonthId.text_en : endMonthId.text_bn) + ', ' + this.$n(startDate[0], { useGrouping: false })
          }
          return customDate
      },
      async loadData () {
          this.dataLoad = false
          this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
          const params = Object.assign({}, this.search)
          const result = await RestApi.getData(internationalTradeFairServiceBaseUrl, 'dashboard/ditf-ministry-dashboard', params)
          if (result.success) {
              this.dashboardData = result.data
              this.dhakaFairList = result.data.fair_list
              this.dataLoad = true
          } else {
            this.dashboardData = {
              foreign_stall: 0,
              local_stall: 0,
              total_stall: 0
            }
            this.dataLoad = true
          }
          this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      },
      dhakaFair () {
          this.search.fair_id = 0
          return this.$store.state.TradeFairService.ditfCommonObj.dhakaFairList.filter(item => item.year === this.search.year).map(item => {
              if (item !== '') {
                  this.search.fair_id = item.value
              }
              return { value: item.value, text: this.$i18n.locale === 'bn' ? item.text_bn : item.text_en }
          })
      }
    }
  }
</script>
<style scoped>
.trade_fire_wrapper {
  border-radius: .2rem;
  background: #55A6F8 url(../../../../../assets/images/card-bg2.png);
  background-size: cover;
  background-position: left;
}
.trade_fire_wrapper .info-card {
  border-radius: 4px;
  color: #fff;
  padding: 1rem;
  min-height: 130px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.trade_fire_wrapper .info-card .content{
  text-align: center;
}
.trade_fire_wrapper .info-card .content p {
  font-size: 20px;
}
.trade_fire_wrapper .info-card img {
  height: 50px;
}
.trade_fire_wrapper .info-card.upcoming {
  background: linear-gradient(90deg, #dc3545 0%, rgb(252 80 42) 100%);
}

.trade_fire_wrapper .info-card.total {
  background: linear-gradient(90deg, #7d1dff 0%, #9448fa 100%);
}

.trade_fire_wrapper .info-card.atended {
  background: linear-gradient(90deg, #e39630 0%, #eecc61 100%);;
}

.upcoming-fair {
  background-image: url(../../../../../assets/images/bg-3.png), linear-gradient(90deg, #7716fe 0%, #9d56fa 100%);
  background-repeat: no-repeat;
  background-size: cover !important;
  position: relative;
}

.upcoming-fair .icon img {
  width: 100px;
}

.upcoming-fair .content {
  padding: 1rem;
  width: 100%;
  color: #fff;
  text-align: center;
}

.ditf_participant .info-card {
  display: flex;
  align-items: center;
  padding: 1rem;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.ditf_participant .info-card .icon {
  padding-right: 1rem;
  color: #fff;
  font-size: 1.5rem;
  margin-right: .6rem;
}

.ditf_participant .info-card.applicant {
  background: linear-gradient(90deg, #520979 20%, #f9efff 23%);
}

.ditf_participant .info-card.approved {
  background: rgb(33, 194, 40);
  background: linear-gradient(90deg, #21c228 20%, #effff0 23%);
}

.ditf_participant .info-card.peinding {
  background: #C96BE5;
  background: linear-gradient(90deg, #C96BE5 20%, #faebff 23%);
}

.ditf_participant .info-card.reject {
  background: #F8576A;
  background: linear-gradient(90deg, #F8576A 20%, #ffeef0 23%);
}

.ditf_participant .info-card.paid {
  background: #6349F8;
  background: linear-gradient(90deg, #6349F8 20%, #efecff 23%);
}

.ditf_participant .info-card.unpaid {
  background: #E79B55;
  background: linear-gradient(90deg, #E79B55 20%, #fff7ef 23%);
}

.ditf_participant .info-card.atended {
  background: #55A6F8;
  background: linear-gradient(90deg, #55A6F8 20%, #eaf5ff 23%);
}

.ditf_participant .info-card.foreign {
  background: #2943d6;
  background: linear-gradient(90deg, #2943d6 20%, #e9ecff 23%);
}
</style>
