<template>
    <div class="cip_db">
        <b-card-body>
            <b-row>
                <b-col sm="3">
                    <b-form-group :label="$t('ministry_dashboard.year_cip')" label-for="year">
                        <b-form-select
                            plain
                            v-model="search.year"
                            :options="yearList"
                            id="year"
                            >
                            <template v-slot:first>
                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                        </b-form-select>
                    </b-form-group>
                </b-col>
                <b-col sm="3">
                    <b-form-group :label="$t('ministry_dashboard.fiscal_year_export_trophy')" label-for="fiscal-year">
                      <b-form-select
                        plain
                        v-model="search.fiscal_year_id"
                        :options="fiscalYearList"
                        id="fiscal_year"
                        >
                        <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                    </b-form-select>
                    </b-form-group>
                </b-col>
                <b-col sm="3">
                    <div class="mt-3">
                        <b-button size="sm" variant="secondary" @click="searchData">{{ $t('globalTrans.search') }}</b-button>
                    </div>
                </b-col>
            </b-row>
            <hr>
            <b-overlay :show="loading">
              <b-row>
                  <div class="col-sm-4">
                      <div class="info_card card_1">
                          <div class="title">
                              <p>{{ $t('globalTrans.total_application') }}</p>
                          </div>
                          <div class="card-content">
                              <div class="mr-2" v-b-modal.modal-cip-application>
                                  <p class="name"> {{ $t('exportTrophyConfig.cip') }} </p>
                                  <h2 class="data"> {{ $n(resultData.totalCipApplication) }} </h2>
                              </div>
                              <div v-b-modal.modal-trophy-application>
                                  <p class="name"> {{ $t('exportTrophyConfig.export_trophy') }} </p>
                                  <h2 class="data"> {{ $n(resultData.totalExpApplication) }} </h2>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="col-sm-4">
                      <div class="info_card card_2" v-b-modal.modal-cip-gazette>
                          <div class="card-content">
                              <p class="title"> {{ $t('policyAdvocacy.total_approved') }} {{ $t('exportTrophyConfig.cip') }} {{ $t('exportTrophyGazette.gazette') }}</p>
                              <h2 class="count sale_count"> {{ $n(resultData.totalCipApprApplication) }} </h2>
                          </div>
                          <div class="icon">
                              <svg width="58" height="44" viewBox="0 0 58 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <rect width="58" height="44" rx="10" fill="#4991EC"/>
                                  <path d="M38.4375 33.625H20.5625C19.1914 33.625 18.125 32.5586 18.125 31.1875V13.3125C18.125 11.9922 19.1914 10.875 20.5625 10.875H38.4375C39.7578 10.875 40.875 11.9922 40.875 13.3125V31.1875C40.875 32.5586 39.7578 33.625 38.4375 33.625ZM28.0273 28.6484L37.3711 19.3047C37.6758 19 37.6758 18.4922 37.3711 18.1875L36.2031 17.0195C35.8984 16.7148 35.3906 16.7148 35.0859 17.0195L27.4688 24.6367L23.8633 21.082C23.5586 20.7773 23.0508 20.7773 22.7461 21.082L21.5781 22.25C21.2734 22.5547 21.2734 23.0625 21.5781 23.3672L26.8594 28.6484C27.1641 29.0039 27.7227 29.0039 28.0273 28.6484Z" fill="#F1F7FF"/>
                              </svg>
                          </div>
                      </div>
                  </div>
                  <div class="col-sm-4">
                      <div class="info_card card_3" v-b-modal.modal-trophy-gazette>
                          <div class="card-content">
                              <p class="title"> {{ $t('policyAdvocacy.total_approved') }} {{ $t('exportTrophyConfig.export_trophy') }}  {{ $t('exportTrophyGazette.gazette') }}</p>
                              <h2 class="count sale_count"> {{ $n(resultData.totalExpApprApplication) }} </h2>
                          </div>
                          <div class="icon">
                              <svg width="58" height="44" viewBox="0 0 58 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <rect width="58" height="44" rx="10" fill="#68B901"/>
                                  <path d="M38.4375 33.625H20.5625C19.1914 33.625 18.125 32.5586 18.125 31.1875V13.3125C18.125 11.9922 19.1914 10.875 20.5625 10.875H38.4375C39.7578 10.875 40.875 11.9922 40.875 13.3125V31.1875C40.875 32.5586 39.7578 33.625 38.4375 33.625ZM28.0273 28.6484L37.3711 19.3047C37.6758 19 37.6758 18.4922 37.3711 18.1875L36.2031 17.0195C35.8984 16.7148 35.3906 16.7148 35.0859 17.0195L27.4688 24.6367L23.8633 21.082C23.5586 20.7773 23.0508 20.7773 22.7461 21.082L21.5781 22.25C21.2734 22.5547 21.2734 23.0625 21.5781 23.3672L26.8594 28.6484C27.1641 29.0039 27.7227 29.0039 28.0273 28.6484Z" fill="#F1F7FF"/>
                              </svg>
                          </div>
                      </div>
                  </div>
              </b-row>

              <b-row>
                  <b-col md="6">
                      <b-card class="mt-3" :title="cipTitle">
                      <div id="chart">
                          <apexchart type="bar" height="350" :options="CipChartOptions" :series="cipSeries"></apexchart>
                      </div>
                      </b-card>
                  </b-col>
                  <b-col md="6">
                      <b-card class="mt-3" :title="exportTrophyTitle">
                      <div id="chart">
                          <apexchart type="bar" height="350" :options="exportTrophyChartOptions" :series="exportTrophySeries"></apexchart>
                      </div>
                      </b-card>
                  </b-col>
              </b-row>
            </b-overlay>
        </b-card-body>
        <b-modal id="modal-cip-application" size="xl" scrollable :title="$t('cip.application_list')" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <CipApplicationListModal :key="search" :search="search"/>
        </b-modal>
        <b-modal id="modal-trophy-application" size="xl" scrollable :title="$t('cip.application_list')" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <TrophyApplicationListModal :key="search" :search="search"/>
        </b-modal>
        <b-modal id="modal-cip-gazette" size="xl" scrollable :title="$t('exportTrophyGazette.gazette') + ' ' +$t('globalTrans.list')" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <CipGazetteListModal :key="search" :search="search"/>
        </b-modal>
        <b-modal id="modal-trophy-gazette" size="xl" scrollable :title="$t('exportTrophyGazette.gazette') + ' ' +$t('globalTrans.list')" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <TrophyGazetteListModal :key="search" :search="search"/>
        </b-modal>
    </div>
</template>
<script>
import RestApi, { exportTrophyCIPServiceBaseUrl } from '@/config/api_config'
import CipApplicationListModal from './CipApplicationListModal.vue'
import TrophyApplicationListModal from './TrophyApplicationListModal.vue'
import CipGazetteListModal from './CipGazetteListModal.vue'
import TrophyGazetteListModal from './TrophyGazetteListModal.vue'
export default {
  props: ['yearList'],
  components: {
    CipApplicationListModal,
    TrophyApplicationListModal,
    CipGazetteListModal,
    TrophyGazetteListModal
  },
  data () {
    return {
      loading: false,
      search: {
        year: new Date().getFullYear() + 1,
        fiscal_year_id: 0,
        fiscal_year_id_arr: [],
        fiscal_year_sorting_order_arr: []
      },
      resultData: {
        totalCipApplication: 0,
        totalCipApprApplication: 0,
        totalExpApplication: 0,
        totalExpApprApplication: 0
      },
      cipApplicationData: [],
      cipGazApproveData: [],
      cipCategories: [],
      exportTrophyData: [],
      exportTrophyApplicationData: [],
      exportTrophyGazApproveData: [],
      exportTrophyCategories: [],
      exportTrophyCategoriesBn: []
    }
  },
  async created () {
    const fiscalYearItem = this.fiscalYearList.find(item => item.value === parseInt(this.currentFiscalYearId))
    const fiscalYearItemBefore = this.fiscalYearList.find(item => item.sorting_order === parseInt(fiscalYearItem.sorting_order - 1))
    this.search.fiscal_year_id = fiscalYearItemBefore.value
  },
  async mounted () {
    await this.searchData()
  },
  computed: {
    currentLocale () {
      return this.$i18n.locale
    },
    fiscalYearList () {
      return this.$store.state.CommonService.commonObj.fiscalYearList
    },
    currentFiscalYearId () {
      return this.$store.state.currentFiscalYearId
    },
    cipSeries () {
      return [{
          name: this.$t('exportTrophyConfig.total_cip_application'),
          data: this.cipApplicationData
        }, {
          name: this.$t('exportTrophyConfig.total_gazette_approved'),
          data: this.cipGazApproveData
      }]
    },
    exportTrophySeries () {
      return [{
          name: this.$t('exportTrophyConfig.total_trophy_application'),
          data: this.exportTrophyApplicationData
        }, {
          name: this.$t('exportTrophyConfig.total_gazette_approved'),
          data: this.exportTrophyGazApproveData
      }]
    },
    cipTitle () {
      return this.$t('exportTrophyConfig.cip_data_chart')
    },
    exportTrophyTitle () {
      return this.$t('exportTrophyConfig.export_trophy_data_chart')
    },
    CipChartOptions () {
      return {
        chart: {
            type: 'bar',
            height: 350
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '25px',
            endingShape: 'rounded',
            dataLabels: {
              position: 'top'
            }
          }
        },
        dataLabels: {
          enabled: true,
          offsetY: 10,
          formatter: function (val) {
            return val
          },
          style: {
            fontSize: '12px',
            colors: ['#FFF']
          }
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          categories: this.loadNumbers(this.cipCategories)
        },
        yaxis: {
          title: {
            text: ''
          }
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val
            }
          }
        }
      }
    },
    exportTrophyChartOptions () {
      return {
        chart: {
            type: 'bar',
            height: 350
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '25px',
            endingShape: 'rounded',
            dataLabels: {
              position: 'top'
            }
          }
        },
        dataLabels: {
          enabled: true,
          offsetY: 10,
          formatter: function (val) {
            return val
          },
          style: {
            fontSize: '12px',
            colors: ['#FFF']
          }
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          categories: this.loadFiscalYearNames(this.exportTrophyCategories)
        },
        yaxis: {
          title: {
            text: ''
          }
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val
            }
          }
        }
      }
    }
  },
  watch: {
    currentLocale: function (newVal, oldVal) {
        if (newVal !== oldVal) {
            //
        }
    },
    'search.fiscal_year_id': function (newVal) {
        // this.search.fiscal_year_id_arr = this.getThreeFiscalYearIds(newVal)
    }
  },
  methods: {
    getThreeFiscalYearIds (fiscalYearId, showSortingOrder = false) {
      if (!fiscalYearId) {
        fiscalYearId = this.currentFiscalYearId
      }
      const obj = this.fiscalYearList.find(item => item.value === parseInt(fiscalYearId))
      const sortingOrder = parseInt(obj.sorting_order)
      const threeFiscalYearList = this.fiscalYearList.filter(item => item.sorting_order >= (sortingOrder - 2) && (item.sorting_order <= sortingOrder))
      const fiscalYearIdArr = []
      const fiscalYearSortingOrderArr = []
      threeFiscalYearList.forEach(item => {
        fiscalYearIdArr.push(parseInt(item.value))
        fiscalYearSortingOrderArr.push(parseInt(item.sorting_order))
      })
      if (showSortingOrder) {
        return fiscalYearSortingOrderArr
      }
      return fiscalYearIdArr
    },
    loadFiscalYearNames (fiscalYearIdArr) {
      if (fiscalYearIdArr.length) {
        return fiscalYearIdArr.map(fiscalYearId => {
          const obj = this.fiscalYearList.find(item => item.value === parseInt(fiscalYearId))
          return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
        })
      }
    },
    loadNumbers (numberArr) {
      if (numberArr.length) {
        return numberArr.map(numberItem => {
          return this.$n(parseInt(numberItem), { useGrouping: false })
        })
      }
    },
    async searchData () {
      this.loading = true
      let result = null
      this.cipApplicationData = []
      this.cipGazApproveData = []
      this.cipCategories = []
      this.exportTrophyApplicationData = []
      this.exportTrophyGazApproveData = []
      this.exportTrophyCategories = []
      this.search.fiscal_year_id_arr = this.getThreeFiscalYearIds(this.search.fiscal_year_id)
      this.search.fiscal_year_sorting_order_arr = this.getThreeFiscalYearIds(this.search.fiscal_year_id, true)
      result = await RestApi.getData(exportTrophyCIPServiceBaseUrl, 'ministry-dashboard', this.search)
      if (result.success) {
        this.resultData = Object.assign({}, this.resultData, result.data)
        this.cipApplicationData = this.resultData.cipDataChart.cipApplicationData
        this.cipGazApproveData = this.resultData.cipDataChart.cipGazApproveData
        this.cipCategories = this.resultData.cipDataChart.cipCategories
        this.exportTrophyApplicationData = this.resultData.exportDataChart.exportTrophyApplicationData
        this.exportTrophyGazApproveData = this.resultData.exportDataChart.exportTrophyGazApproveData
        this.exportTrophyCategories = this.resultData.exportDataChart.exportTrophyCategories
        // this.cipDatas = result.data
        // const yearListValues = this.cipDatas.cipDataChart
        // const tmpYearList = this.yearList.filter(el => el.value <= this.currentYear).slice(-5)

        // tmpYearList.map(el => {
        //   const tmpYearVal = yearListValues.find(tmp => parseInt(tmp.year) === parseInt(el.value))

        //   const year = el.text
        //   const totalApplication = typeof tmpYearVal !== 'undefined' ? parseInt(tmpYearVal.totalApplication) : 0
        //   const totalGazete = typeof tmpYearVal !== 'undefined' ? parseInt(tmpYearVal.totalGazete) : 0

        //   this.cipApplicationData.push(parseInt(totalApplication))
        //   this.cipGazApproveData.push(parseInt(totalGazete))
        //   this.cipCategories.push(year)
        // })
        // this.exportTrophyData = result.data
        // const fiscalYearListValues = this.exportTrophyData.exportDataChart
        // const tmpFiscalYearList = this.fiscalYearList.filter(el => {
        //   if (el.sorting_order <= this.currentFiscalSortingId) {
        //     return el
        //   }
        // }).filter(el => el.value <= this.currentFiscalYearId).slice(-5)

        // this.exportTrophyApplicationData = []
        // this.exportTrophyGazApproveData = []
        // this.exportTrophyCategories = []

        // return tmpFiscalYearList.map(el => {
        //   const tmpFiscalYearVal = fiscalYearListValues.find(tmp => parseInt(tmp.fiscalYear) === parseInt(el.value))

        //   const fiscalYear = el.text
        //   const totalApplication = typeof tmpFiscalYearVal !== 'undefined' ? parseInt(tmpFiscalYearVal.totalApplication) : 0
        //   const totalGazete = typeof tmpFiscalYearVal !== 'undefined' ? parseInt(tmpFiscalYearVal.totalGazete) : 0

        //   this.exportTrophyApplicationData.push(parseInt(totalApplication))
        //   this.exportTrophyGazApproveData.push(parseInt(totalGazete))
        //   this.exportTrophyCategories.push(fiscalYear)
        // })
      }
      //  else {
      //     this.cipDatas = this.cipApplicationData = this.cipGazApproveData = this.cipCategories = []
      //     this.exportTrophyData = this.exportTrophyApplicationData = this.exportTrophyGazApproveData = this.exportTrophyCategories = []
      // }
      this.loading = false
    }
  }
}
</script>
<style lang="scss">
.cip_db{
    .info_card{
        padding: 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 10px;
        border: 1px solid;
        min-height: 7.6rem;
        .card-content{
            margin-left: 10px;
            font-weight: bold;
        }
        &.card_1{
            border-color: #981AFF;
            background-color: #F2E3FF;
            flex-direction: column;
            padding: 0;
            overflow: hidden;
            .title{
              color: #fff;
              background-color: #981AFF;
              text-align: left;
              width: 100%;
              padding: .3rem 1rem;
            }
            .card-content{
              width: 100%;
              padding: .5rem 1rem;
              display: flex;
              justify-content: space-around;
            }
        }
        &.card_2{
            border-color: #377dff;
            background-color: #D6E8FF;
        }
        &.card_3{
            border-color: #68B901;
            background-color: #E8FFCB;
        }
    }
}
</style>
