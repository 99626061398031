<template>
    <div class="pt-3 secretary-db-wrapper scroll-top-60" v-bind:style="styleObject">
        <div class="text-right">
            <button @click="back" class="btn btn-component ml-3 mb-2"><i class="ri-arrow-go-forward-line"></i> {{ $t('ministry_dashboard.components') }}</button>
        </div>
        <div class="accordion" role="tablist">
            <!-- bazar price -->
            <b-card no-body class="mb-2">
                <b-card-header header-tag="header" v-bind:style="styleObject" class="p-0" role="tab" ref="bazar-price">
                    <button v-b-toggle.bazar-price class="accor-header tab-1 btn w-100 text-light">
                        <div>
                            <img src="@/assets/icons/secretary-db-icon/market-price-icon.svg" class="img-fluid mr-3">
                            <span>{{ currentLocale === 'en' ? 'Bazar Price' : 'বাজার দর'}}</span>
                        </div>
                        <span class="accordion-toggle-icon">
                            <i class="ri-arrow-down-s-line"></i>
                        </span>
                    </button>
                </b-card-header>
                <b-collapse visible id="bazar-price" role="tabpanel">
                    <b-card-body>
                        <Bazardor />
                    </b-card-body>
                </b-collapse>
            </b-card>
            <!-- bazar monitoring -->
            <b-card no-body class="mb-2">
                <b-card-header header-tag="header" v-bind:style="styleObject" class="p-0" role="tab" ref="bazar-monitoring">
                    <button v-b-toggle.bazar-monitoring variant="secondary"
                        class="accor-header tab-2 btn w-100 text-light">
                        <div>
                            <img src="@/assets/icons/secretary-db-icon/market-monitoring-icon.svg"
                                class="img-fluid mr-3">
                            <span>{{ currentLocale === 'en' ? 'Bazar Monitoring' : 'বাজার তদারকি'}}</span>
                        </div>
                        <span class="accordion-toggle-icon">
                            <i class="ri-arrow-down-s-line"></i>
                        </span>
                    </button>
                </b-card-header>
                <b-collapse visible id="bazar-monitoring" role="tabpanel">
                    <BazarMonitoring />
                </b-collapse>
            </b-card>
            <!-- export Trophy CIP -->
            <b-card no-body class="mb-2">
                <b-card-header header-tag="header" v-bind:style="styleObject" class="p-0" role="tab" ref="export-trophy-cip">
                    <button block v-b-toggle.export-trophy-cip class="accor-header tab-3 btn w-100 text-light">
                        <div>
                            <img src="@/assets/icons/secretary-db-icon/cip-icon.svg" class="img-fluid mr-3">
                            <span>{{ currentLocale === 'en' ? 'Export Trophy & CIP' : 'রপ্তানি ট্রফি এবং সিআইপি'
                                }}</span>
                        </div>
                        <span class="accordion-toggle-icon">
                            <i class="ri-arrow-down-s-line"></i>
                        </span>
                    </button>
                </b-card-header>
                <b-collapse visible id="export-trophy-cip" role="tabpanel">
                    <!-- import component -->
                    <CipTrophy :yearList="yearList" />
                </b-collapse>
            </b-card>
            <!-- fair management -->
            <b-card no-body class="mb-2">
                <b-card-header header-tag="header" v-bind:style="styleObject" class="p-0" role="tab" ref="fair-management">
                    <button v-b-toggle.fair-management class="accor-header tab-4 btn w-100 text-light">
                        <div>
                            <img src="@/assets/icons/secretary-db-icon/fair-icon.svg" class="img-fluid mr-3">
                            <span>{{ currentLocale === 'en' ? 'Fair Management' : 'মেলা ব্যবস্থাপনা' }}</span>
                        </div>
                        <span class="accordion-toggle-icon">
                            <i class="ri-arrow-down-s-line"></i>
                        </span>
                    </button>
                </b-card-header>
                <b-collapse visible id="fair-management" role="tabpanel">
                    <!-- import component -->
                    <b-card-body>
                        <!--dhaka international trade fair -->
                        <DITF />

                        <!-- international trade fair -->
                        <ITF />
                    </b-card-body>
                </b-collapse>
            </b-card>
            <!-- tea garden -->
            <b-card no-body class="mb-2">
                <b-card-header header-tag="header" v-bind:style="styleObject" class="p-0" role="tab" ref="tea-garden">
                    <button block v-b-toggle.tea-garden class="accor-header tab-5 btn w-100 text-light">
                        <div>
                            <img src="@/assets/icons/secretary-db-icon/tea-garden-icon.svg" class="img-fluid mr-3">
                            <span>{{$t('secretaryDb.tea_garden_ms')}}</span>
                        </div>
                        <span class="accordion-toggle-icon">
                            <i class="ri-arrow-down-s-line"></i>
                        </span>
                    </button>
                </b-card-header>
                <b-collapse visible id="tea-garden" role="tabpanel">
                    <b-card-body>
                        <TeaGarden :yearList="yearList" />
                    </b-card-body>
                </b-collapse>
            </b-card>
            <!-- tcb dealer  -->
            <b-card no-body class="mb-2">
                <b-card-header header-tag="header" v-bind:style="styleObject" class="p-0" role="tab" ref="tcb-dealer">
                    <button block v-b-toggle.tcb-dealer class="accor-header tab-6 btn w-100 text-light">
                        <div>
                            <img class="img-fluid mr-3" src="@/assets/icons/secretary-db-icon/tcb-icon.svg">
                            <span>{{ currentLocale === 'en' ? 'TCB Dealer Management' : 'টিসিবি ডিলার ব্যবস্থাপনা'}}</span>
                        </div>
                        <span class="accordion-toggle-icon">
                            <i class="ri-arrow-down-s-line"></i>
                        </span>
                    </button>
                </b-card-header>
                <b-collapse visible id="tcb-dealer" role="tabpanel">
                    <b-card-body>

                        <!-- for tcb dealer -->
                        <TcbDealer />

                        <!-- for allocation management -->
                        <TcbAllocation/>

                    </b-card-body>
                </b-collapse>
            </b-card>
        </div>
    </div>
</template>

<script>
import RestApi, { commonServiceBaseUrl } from '@/config/api_config'
import TeaGarden from './components/TeaGarden.vue'
import Bazardor from './Bazardor.vue'
import DITF from './components/DITF.vue'
import ITF from './components/ITF.vue'
import BazarMonitoring from './components/BazarMonitoring'
import CipTrophy from './components/CipTrophy'
import TcbDealer from './components/TcbDealer'
import TcbAllocation from './components/TcbAllocation'
import { EventBus } from '@/EventBusLayout'
import { helpers } from '@/utils/helper-functions'
import { mapGetters } from 'vuex'
export default {
    components: {
        CipTrophy, TeaGarden, Bazardor, DITF, ITF, BazarMonitoring, TcbDealer, TcbAllocation
    },
    data () {
      return {
        dashboardNames: {
            teagarden: '',
            tcb_dealer: ''
        },
        fiscalYearList: [],
        currentFiscalYearId: 0,
        styleObject: {
          scrollMarginTop: '-270px'
        }
      }
    },
    computed: {
      currentLocale () {
        return this.$i18n.locale
      },
      yearList () {
        return helpers.getYearList({ yearSpan: 4, yearMax: 1, sorting_order: 2 })
      },
      monthList () {
        return this.$store.state.commonObj.monthList.map(item => {
            return Object.assign({}, item, { text: this.currentLocale === 'en' ? item.text_en : item.text_bn })
        })
      },
      ...mapGetters({
            authUser: 'Auth/authUser'
        })
    },
    created () {
      if (parseInt(this.authUser.dashboard_user) === 0) {
        this.$router.push('/auth/dashboard')
      }
    //   this.loadDropdownList()
    },
    beforeMount () {
      EventBus.$on('MINISTRY_DASHBOARD_NAMES', this.setMinistyDashboardNames)
    },
    watch: {
      currentLocale: function (newVal) {
        this.fiscalYearList = this.fiscalYearList.map(item => {
            return Object.assign({}, item, { text: newVal === 'en' ? item.text_en : item.text_bn })
        })
        // this.yearList = this.yearList.map(item => {
        //     return Object.assign({}, item, { text: newVal === 'en' ? item.text_en : item.text_bn })
        // })
        // this.monthList = this.monthList.map(item => {
        //     return Object.assign({}, item, { text: newVal === 'en' ? item.text_en : item.text_bn })
        // })
      },
      $route () {
        this.setScrollPosition()
      }
    },
    methods: {
     back () {
        this.$router.push('/auth/dashboard')
     },
      setMinistyDashboardNames (menus) {
        const tmp = this.dashboardNames
        menus.map(item => {
            const urlLastSegment = item.url.split('/')[3]
            tmp[urlLastSegment] = item.name
        })
        this.dashboardNames = tmp
      },
      setScrollPosition () {
        this.$refs[this.$route.params.dashboard_name].scrollIntoView({ behavior: 'smooth', block: 'center' })
        // window.scrollBy(0, 100)
      },
      fiscalYearBnAdd (data) {
        const bnList = ['০', '১', '২', '৩', '৪', '৫', '৬', '৭', '৮', '৯']
        const bnAdded = data.map(item => {
            const fiscalYearData = {}
            const result = item.text_bn.split('').map(item => item === '-' ? '-' : bnList[item])
            fiscalYearData.text_bn = result.join('')
            return Object.assign({}, item, fiscalYearData)
        })
        return bnAdded
      },
      setCurrentFiscalYear (fiscalYearList) {
        if (fiscalYearList.length === 0) {
            return
        }
        const currentDate = new Date()
        const [month, year] = [currentDate.getMonth() + 1, currentDate.getFullYear()]
        /** Define the year position  */
        const yearPosition = month < 7 ? 5 : 0
        const yearStr = `${year}`
        let currentFiscalYearId = 0
        fiscalYearList.forEach(element => {
            if (element.text_en.indexOf(yearStr) === yearPosition) {
                currentFiscalYearId = element.value
            }
        })
        this.currentFiscalYearId = currentFiscalYearId
      },
      async loadDropdownList () {
        const result = await RestApi.getData(commonServiceBaseUrl, 'ministry-common-dropdown')
        if (result.success) {
            this.fiscalYearList = this.fiscalYearBnAdd(result.data.fiscalYearList).map(item => {
                return Object.assign({}, item, { text: this.currentLocale === 'en' ? item.text_en : item.text_bn })
            })
            this.setCurrentFiscalYear(this.fiscalYearList)
        }
      }
    //   loadFilteredFiscalYearList (fiscalYearList) {

    //   },
    //   checkStartDate (item) {
    //     const startDateTime = new Date(item.start_date).getTime()
    //     const previousDateTime = new Date(new Date().setFullYear(new Date().getFullYear() - 4)).getTime()
    //     return previousDateTime >= startDateTime
    //   }
    //   checkEndDate (item) {
    //     const startDateTime = new Date(item.start_date).getTime()
    //     const previousDateTime = new Date(new Date().setFullYear(new Date().getFullYear() - 4)).getTime()
    //     return previousDateTime >= startDateTime
    //   }
    }
}
</script>
<style lang="scss">
.section-title{
    height: 18px;
    display: flex;
}
.section-title .line{
  width:100%;
  border-bottom:2px solid #ccc;
}
.section-title .text{
  position: relative;
  top:0;
  padding:5px;
  font-weight: bold;
  text-wrap: nowrap;
}

.secretary-db-wrapper .accor-header{
    overflow-anchor: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.accor-header.tab-1{
    background-color: #234972;
    &.not-collapsed{
        background-color: #234972!important;
    }
}
.accor-header.tab-2{
    background-color: #19579C;
    &.not-collapsed{
        background-color: #19579C!important;
    }
}
.accor-header.tab-3{
    background-color: #1D3B88;
    &.not-collapsed{
        background-color: #1D3B88!important;
    }
}
.accor-header.tab-4{
    background-color: #2E2C7E;
    &.not-collapsed{
        background-color: #2E2C7E!important;
    }
}
.accor-header.tab-5{
    background-color: #6E2862;
    &.not-collapsed{
        background-color: #6E2862!important;
    }
}
.accor-header.tab-6{
    background-color: #225831;
    &.not-collapsed{
        background-color: #225831!important;
    }
}
.secretary-db-wrapper .accordion-toggle-icon {
    margin-right: 0.5rem;
    transition: transform 0.3s ease;
}
.secretary-db-wrapper .accordion-toggle-icon i{
    font-size: 30px;
    line-height: 30px;
    display: block;
}

.secretary-db-wrapper .not-collapsed span i{
    transform: rotate(180deg);
}
.secretary-db-wrapper .secretary-db-card{
    margin-top: 20px;
    padding: 10px;
    display: flex;
    border-radius: 5px;
}
.secretary-db-wrapper .secretary-db-card .icon{
    color: #fff;
    font-size: 20px;
    line-height: 1;
    text-align: center;
    width: 34px;
    height: 34px;
    padding: 5px;
    border-radius: 5px;
}
.secretary-db-wrapper .tea-garden-card .card{
    padding: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-radius: 5px;
    background: linear-gradient(67deg, #2DDD8B 51.69%, #45DFA3 51.7%);
}
.secretary-db-wrapper .tea-garden-card .card .icon{
    padding: 0.2rem;
    border-radius: 5px;
    background-color: #fff;
}
.secretary-db-wrapper .tea-garden-card .card .icon img{
    max-width: 100px;
    min-height: 67px;
    border-radius: 5px;
}
.secretary-db-wrapper .std-card-list.design-1 .secretary-db-card .icon{
    margin-top: -25px;
    margin-right: 5px;
}
.secretary-db-wrapper .std-card-list.design-2 .secretary-db-card .icon{
    margin-top: 12px;
    margin-right: 5px;
}
.secretary-db-wrapper .std-card-list.design-3 .secretary-db-card{
    flex-direction: column;
    align-items: center;
}
.secretary-db-wrapper .std-card-list.design-3 .secretary-db-card .icon{
    width: 100%;
    height: auto;
}
.secretary-db-wrapper .std-card-list.design-3 .secretary-db-card .icon .title{
    font-size: 15px;
}
.secretary-db-wrapper .std-card-list.design-4 .secretary-db-card{
    flex-direction: column;
    align-items: center;
}
.secretary-db-wrapper .std-card-list.design-5 .secretary-db-card{
    justify-content: space-between;
    align-items: center;
}
.secretary-db-wrapper .std-card-list.design-4 .secretary-db-card .card-content .count{
    text-align: center;
}
.secretary-db-wrapper .std-card-list .secretary-db-card.yallow{
    background-color: #fff4e4;
    border: 2px solid #ffb554;
}
.secretary-db-wrapper .std-card-list .secretary-db-card.yallow .icon{
    background-color: #ffb554;
}
.secretary-db-wrapper .std-card-list .secretary-db-card.rad{
    background-color: #ffe8e8;
    border: 2px solid #FF5454;
}
.secretary-db-wrapper .std-card-list .secretary-db-card.rad .icon{
    background-color: #FF5454;
}
.secretary-db-wrapper .std-card-list .secretary-db-card.green{
    background-color: #f4ffda;
    border: 2px solid #83ce20;
}
.secretary-db-wrapper .std-card-list .secretary-db-card.green .icon{
    background-color: #83ce20;
}
.secretary-db-wrapper .std-card-list .secretary-db-card.sky{
    background-color: #d7feff;
    border: 2px solid #09a8cf;
}
.secretary-db-wrapper .std-card-list .secretary-db-card.sky .icon{
    background-color: #09a8cf;
}
.secretary-db-wrapper .std-card-list .secretary-db-card.blue{
    background-color: #cce8ff;
    border: 2px solid #095fcf;
}
.secretary-db-wrapper .std-card-list .secretary-db-card.blue .icon{
    background-color: #095fcf;
}
.secretary-db-wrapper .std-card-list .secretary-db-card.purple{
    background-color: #e2ccff;
    border: 2px solid #5f09cf;
}
.secretary-db-wrapper .std-card-list .secretary-db-card.purple .icon{
    background-color: #5f09cf;
}
.secretary-db-wrapper .std-card-list .secretary-db-card.pink{
    background-color: #ffd5ec;
    border: 2px solid #cf0983;
}
.secretary-db-wrapper .std-card-list .secretary-db-card.pink .icon{
    background-color: #cf0983;
}
.scroll-top-60 {
    scroll-margin-top: 60px;
    -webkit-scroll-margin-top: 60px;
    overflow: initial !important;
}
.btn-component{
    background-color: #095fcf;
    color: #fff;
}
.accordion {
    overflow-x: hidden;
    overflow-y: scroll;
    scroll-snap-type: y mandatory;
}
</style>
